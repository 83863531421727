export const BOX_COORDS = {
  '1': {
    y: '85%',
    x: '20%',
    fromRight: true,
  },
  '2': {
    y: '70%',
    x: '15%',
    fromRight: true,
  },
  '3': {
    y: '75%',
    x: '30%',
    fromRight: true,
  },
  '4': {
    y: '60%',
    x: '32%',
    fromRight: true,
  },
  '5': {
    y: '65%',
    x: '40%',
    fromRight: true,
  },
  '6': {
    y: '50%',
    x: '40%',
    fromRight: true,
  },
  '7': {
    y: '60%',
    x: '50%',
    fromRight: true,
  },
  '8': {
    y: '45%',
    x: '50%',
    fromRight: true,
  },
  '9': {
    y: '52%',
    x: '58%',
    fromRight: true,
  },
  '10': {
    y: '40%',
    x: '56%',
    fromRight: true,
  },
  '11': {
    y: '45%',
    x: '65%',
    fromRight: true,
  },
  '12': {
    y: '35%',
    x: '62%',
    fromRight: true,
  },
  '13': {
    y: '40%',
    x: '70%',
    fromRight: true,
  },
  '14': {
    y: '32%',
    x: '68%',
    fromRight: true,
  },
  '15': {
    y: '37%',
    x: '73%',
    fromRight: true,
  },
  '16': {
    y: '29%',
    x: '71%',
    fromRight: true,
  },
  '17': {
    y: '35%',
    x: '71%',
    fromRight: true,
  },
  '18': {
    y: '26%',
    x: '71%',
    fromRight: true,
  },
  '19': {
    y: '32%',
    x: '15%',
  },
  '20': {
    y: '20%',
    x: '19%',
  },
  '21': {
    y: '29%',
    x: '13%',
  },
  '22': {
    y: '21%',
    x: '16%',
  },
  '23': {
    y: '28%',
    x: '10%',
  },
  '24': {
    y: '20%',
    x: '12%',
  },
  '25': {
    y: '25%',
    x: '7%',
  },
  '26': {
    y: '18%',
    x: '9%',
  },
};

export const VECTOR = {
  width: 1920,
  height: 958,
  paths: [
    {
      number: '1',
      path:
        'M1502.5 814.5L1519 805.5L1589 842L1760.5 746.5V647L1589 730.5L1519 698L1502.5 705L1321 624.5V716.5L1378 745.5V750L1502.5 814.5Z',
    },
    {
      number: '2',
      path:
        'M1760.5 647L1589 730.5L1519 698L1502.5 705L1321 624.5V507.5L1331 504L1343 505L1378.5 492.5L1427.5 417.5L1541.5 454.5V452.5L1548 449.5L1635 452.5L1728 505.5L1728.5 510.5L1734.5 513L1728.5 517V541.5L1760.5 578.5V647Z',
    },
    {
      number: '3',
      path:
        'M1129 618L1157.5 632.5V647.5L1249 695L1252.5 693L1305 720V626.5L1157.5 559.5V547L1128.7 533.5L1129 618Z',
    },
    {
      number: '4',
      path:
        'M1305 514.5V503L1313.5 500V493.5L1375.5 474L1419.5 404L1426.5 406.5L1440.5 421.5L1477 433.5L1415 396L1347.5 394.5L1338.5 397V400L1240 367.5L1225 352.5L1216.5 349.5L1128.5 476.5L1128.7 533.5L1157.5 547V559.5L1305 626.5V514.5Z',
    },
    {
      number: '5',
      path:
        'M1035.5 584L1076 602.5L1129 585.5L1128.63 505.5L1076.5 521L1035 501.5L1020.5 505.5L915.727 456.5L915.5 529L950 547V550L1020.5 588L1032 583L1035.5 584Z',
    },
    {
      number: '6',
      path:
        'M1128.5 476.5L1212 355.5L1175 331.5L1120.5 332L1115 334V337.5L1014.5 304L971.5 359.5L941 366.5H926.5L916 369L915.727 456.5L1020.5 505.5L1035 501.5L1076.5 521L1128.63 505.5L1128.5 476.5Z',
    },
    {
      number: '7',
      path:
        'M804 402.5L818 409V419.5L906.404 461L905.5 532L876 517L871.5 518L817.5 489V477.5L804 470V402.5Z',
    },
    {
      number: '8',
      path:
        'M907.5 375V366.5L912 365V359L969.5 346.5L999 308.5L1007 298H995L988 300V304L902.5 275L889 262.5L883.5 260.5L821.5 335.5L817.5 336.5V342L804 358V402.5L818 409V419.5L906.404 461L907.5 375Z',
    },
    {
      number: '9',
      path:
        'M771 462.5L803.5 454V389.5L771 397L744.5 384.5L731.5 388L663.5 353.5V412.5L685.5 424V427L732.5 452L745 448L771 462.5Z',
    },
    {
      number: '10',
      path:
        'M803.5 358L816 342.5L817.5 336.5L821.5 335.5L883.5 260.5L889 262.5L903 275L917.5 280L882 254L847.5 256.5L844 255.5L843 257H840V260L835 260.5L752 232L712 277L704 278.5L663.5 281.5V353.5L731.5 388L744.5 384.5L771 397L803.5 389.5V358Z',
    },
    {
      number: '11',
      path:
        'M654.5 414.5L658 413.5V358.5L597.5 329V320L591.197 317L591.5 374.5L597 376V386.5L633.5 406L636.5 405.5L654.5 414.5Z',
    },
    {
      number: '12',
      path:
        'M597.5 272.5L591 279.5L591.197 317L597.5 320V329L658 358.5V280.5H661V274L712 265.5L739 234.5L679 213L666.5 202.5L662.5 201L609.5 259L597.5 260.5V272.5Z',
    },
    {
      number: '13',
      path:
        'M568.5 370.5L591 365.5V314L569 318.5L550 309.5L538 311L490.5 286.5V337L538 365.5L549.5 362.5L568.5 370.5Z',
    },
    {
      number: '14',
      path:
        'M591 279.5L597 272.5V260.5L609.5 259L657 207L648 208V210.5H646L639 204.5L635 203L630.5 207.5L584.5 191L569.5 185.5L535 222L523 223.5L521.5 222.5L490.5 226.5V286.5L538 311L550 309.5L569 318.5L591 314V279.5Z',
    },
    {
      number: '15',
      path:
        'M472 331.5L486.5 338.5V291L441 267.5V308.5L443 310.5V318.5L468.5 332.5L472 331.5Z',
    },
    {
      number: '16',
      path:
        'M443.5 225L441 228.5V267.5L486.5 291V226H489.5V220L534.5 214L559 187.5L520.5 173L508 163.5L505 162.5L460.5 208L443.5 211.5V225Z',
    },
    {
      number: '17',
      path:
        'M424.5 306L441 303V258L424.5 261L411 254.5L400 255L364.5 237V279L376.5 286.5V288.5L400 302.5L410.5 300.5L424.5 306Z',
    },
    {
      number: '18',
      path:
        'M441 228.5L443 225L443.5 211.5L460.5 208L496 171.5L485.5 163.5L481.5 167.5L436 150L406 179.5L390.5 181.5L389.5 180.5L382.5 181L364.5 185.5V237L400 255L411 254.5L424.5 261L441 258V228.5Z',
    },
    {
      number: '19',
      path: 'M361.5 283.5V240.5L329.5 224V266.5L361.5 283.5Z',
    },
    {
      number: '20',
      path:
        'M361.5 184.5H364V179.745L406 174.5L428 152L401.5 142L389.5 133L387.5 132.5L350 169L345.5 170L329.5 173.5V224L361.5 240.5V184.5Z',
    },
    {
      number: '21',
      path:
        'M316.5 256.5L329 254.5L329.25 214L316.5 215.5L306.5 211H296L269.5 197V234.5L278.5 239.5V241.5L296 251.5L305.5 250.5L316.5 256.5Z',
    },
    {
      number: '22',
      path:
        'M329.5 173.5L350 169L381.5 138L372 131L368.5 134.5L333.5 121L308 145.5L291 147.5L289.5 146.5L283 147L269.5 151V197L296 211H306.5L316.5 215.5L329.25 214L329.5 173.5Z',
    },
    {
      number: '23',
      path:
        'M241.5 187V224.5L255.5 232.5L257 232L263 235.5L266.5 234.5L266.704 200L241.5 187Z',
    },
    {
      number: '24',
      path:
        'M268.5 150V146.5L306.5 142L327 122.5L308.5 115.5L298 107L296 106.5L262 138.5H253.5L241.5 143V187L266.704 200L267 150H268.5Z',
    },
    {
      number: '25',
      path:
        'M233 218L241.5 217L241.75 180.25L233.5 181.5L225 177L216 178L193.5 165.5V199.5L201.5 204V206L215.5 214.5L224.5 213L233 218Z',
    },
    {
      number: '26',
      path:
        'M242 143.5L253 138.5H262L290.5 112L284 107L281 110L263.5 103L253 95L228 118L193.5 121V165.5L216 178L225 177L233.5 181.5L241.75 180.25L242 143.5Z',
    },
  ],
};
