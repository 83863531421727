import React from 'react';
import styled from 'styled-components';
import Mapbox, { Marker } from 'react-map-gl';
import LogoImg from '../../../assets/images/logo.svg';
import mapbox from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapbox.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const StyledWrapper = styled.div`
  width: 100%;
  height: 39vw;
  max-height: 750px;
  position: relative;
  margin-top: 100px;

  @media (max-width: 720px) {
    height: 55vw;
    margin-top: 80px;
  }
`;

const StyledMapbox = styled(Mapbox)`
  width: 100%;
  height: 100%;
`;

const StyledLogo = styled.img`
  width: 128px;
  height: auto;
`;

const Map = () => {
  return (
    <StyledWrapper>
      <StyledMapbox
        initialViewState={{
          latitude: 50.102163351590804,
          longitude: 19.831479521301844,
          zoom: 14,
        }}
        mapStyle="https://api.maptiler.com/maps/e672e612-b553-484c-a922-fa5e0dc7c699/style.json?key=YdGJFoDdkZpc2BQO4TzP"
        mapboxAccessToken={process.env.GATSBY_MAPS_API_KEY}
        cooperativeGestures
        antialias
      >
        <Marker
          latitude={50.102163351590804}
          longitude={19.831479521301844}
          onClick={() => {}}
          anchor="bottom"
        >
          <StyledLogo src={LogoImg} alt="Marker" />
        </Marker>
      </StyledMapbox>
    </StyledWrapper>
  );
};

export default Map;
