export const getDate = (deliveryDate: number) => {
  let string = '';

  string += new Date(deliveryDate).getFullYear();
  string += 'r. - ';
  string += new Intl.DateTimeFormat('pl-PL', {
    month: 'long',
  }).format(deliveryDate);

  return string;
};
