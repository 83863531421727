import React, { FC } from 'react';
import styled from 'styled-components';
import { Availability, House } from '@rendpro/core-ui';
import { Link } from 'gatsby';
import { cssEaseOutExpo } from '@theme/easing';
import { formatPrice } from '@utils/formatPrice';
import { getDate } from '@utils/getDate';
import { formatNumber } from '@utils/formatNumber';

const StyledWrapper = styled.div`
  position: absolute;
  left: 5px;
  top: 5px;
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 355px;
  font-size: 1.4rem;
  font-weight: 400;
  align-items: center;
  padding-bottom: 35px;
  z-index: 999;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.07);
  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledTopWrapper = styled.div`
  padding: 25px 25px 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(23, 23, 22, 0.5);
  width: 100%;
`;

const StyledInfo = styled.div<{ $availability: Availability }>`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  :nth-of-type(2) {
    min-width: 121px;
    span:last-of-type {
      color: ${({ theme, $availability }) => theme[$availability]};
    }
  }
  span:last-of-type {
    margin-top: 5px;
    font-weight: 600;
    color: ${({ theme, $availability }) => theme[$availability]};
    transition: color 0.4s cubic-bezier(0.33, 1, 0.68, 1);
  }
`;

const StyledTable = styled.div`
  padding: 20px 25px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledItem = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
  :last-of-type {
    margin-bottom: 0;
  }
  span:first-of-type {
    max-width: 55%;
    text-align: right;
    margin-right: 20px;
  }
  span:last-of-type {
    width: 40%;
    font-weight: 600;
  }
`;

const StyledLink = styled(Link)`
  width: 200px;
  font-size: 1.4rem;
  margin-top: 25px;
  display: flex;
  padding: 15px 0;
  border: 1px solid ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.foreground};
  text-decoration: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: background 0.5s ${cssEaseOutExpo}, color 0.5s ${cssEaseOutExpo};

  :hover {
    background: ${({ theme }) => theme.primary};
    color: #fff;
  }

  div:last-of-type {
    left: 35%;
  }
`;

const Box: FC<Props> = ({ currentHouse, className, ...props }) => {
  const {
    number,
    availability,
    price,
    area,
    parcelArea,
    garage,
    deliveryDate,
    roomNumber,
  } = currentHouse;

  return (
    <StyledWrapper className={className} {...props}>
      <StyledTopWrapper>
        <StyledInfo $availability={availability}>
          <span>Lokal:</span>
          <span>{number}</span>
        </StyledInfo>
        <StyledInfo $availability={availability}>
          <span>Status:</span>
          <span>{availability}</span>
        </StyledInfo>
        <StyledInfo $availability={availability}>
          <span>Cena:</span>
          <span>{formatPrice(price)}</span>
        </StyledInfo>
      </StyledTopWrapper>

      <StyledTable>
        <StyledItem>
          <span>Powierzchnia mieszkalna:</span>
          <span>
            {formatNumber(area)} m<sup>2</sup>
          </span>
        </StyledItem>
        <StyledItem>
          <span>Powierzchnia działki:</span>
          <span>
            {parcelArea} m<sup>2</sup>
          </span>
        </StyledItem>
        <StyledItem>
          <span>Ilość pokoi:</span>
          <span>{roomNumber}</span>
        </StyledItem>
        <StyledItem>
          <span>Miejsce postojowe:</span>
          <span>{garage}</span>
        </StyledItem>
        <StyledItem>
          <span>Termin oddania:</span>
          <span>
            {typeof deliveryDate === 'number'
              ? getDate(deliveryDate)
              : deliveryDate}
          </span>
        </StyledItem>
      </StyledTable>

      <StyledLink to={`/mieszkanie/${number}`}>Zobacz więcej</StyledLink>
    </StyledWrapper>
  );
};

interface Props {
  currentHouse: House;
  className?: string;
}

export default Box;
