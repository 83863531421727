import React, { FC } from 'react';
import styled from 'styled-components';
import { Availability } from '../../../enums/availability';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDot = styled.div<{ $availability: Availability }>`
  background: ${({ theme, $availability }) =>
    theme[$availability || Availability.Available]};
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 15px;
  @media (max-width: 720px) {
    width: 20px;
    height: 20px;
  }
`;

const Legend: FC<Props> = ({ availability, className }) => (
  <StyledWrapper className={className}>
    <StyledDot $availability={availability} />
    {availability}
  </StyledWrapper>
);

interface Props {
  availability: Availability;
  className?: string;
}

export default Legend;
