import React from 'react';
import {
  Availability,
  InteractiveVisualization as RendproInteractiveVisualization,
} from '@rendpro/core-ui';
import { useStaticQuery, graphql, navigate, Link } from 'gatsby';
import { useWindowSize } from 'react-use';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import {
  BOX_COORDS,
  VECTOR,
} from '@components/molecules/RendProInteractiveVis/data';
import Box from './Box';
import Legend from '@components/atoms/Legend/Legend';
import CheckboxNew from '@components/atoms/CheckboxNew/CheckboxNew';
import { moveIcon, zoomInIcon, zoomOutIcon } from '@assets/icons';

const StyledWrapper = styled.div`
  position: relative;
  width: 90%;
  max-width: 620px;
  margin: auto;

  @media (min-width: 1025px) {
    width: 90%;
    max-width: 1640px;
    margin: auto;
  }

  @media print {
    display: none;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
`;

const StyledMobileNavItem = styled(Link)`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  border-radius: 50%;
  line-height: 1;
  margin-right: 10px;
  &.second_indication {
    &-available {
      background: ${({ theme }) => theme[Availability.Available]};
    }
    &-reserved {
      background: ${({ theme }) => theme[Availability.Reserved]};
    }
    &-unavailable {
      background: ${({ theme }) => theme[Availability.Unavailable]};
    }
  }
  @media (max-width: 1024px) {
    width: 40px;
    height: 40px;
  }
`;

const StyledIndication = styled(StyledMobileNavItem)`
  position: absolute;
  z-index: 20;
  font-size: 1.4rem;
  margin-right: 0;
  &.indication {
    &-available {
      background: ${({ theme }) => theme[Availability.Available]};
    }
    &-reserved {
      background: ${({ theme }) => theme[Availability.Reserved]};
    }
    &-unavailable {
      background: ${({ theme }) => theme[Availability.Unavailable]};
    }
  }
  @media (max-width: 1440px) {
    width: 20px;
    height: 20px;
    font-size: 1rem;
  }
  @media (max-width: 1024px) {
    width: 10px;
    height: 10px;
    font-size: 0.4rem;
  }
  :nth-of-type(1) {
    left: 73%;
    top: 75%;
  }
  :nth-of-type(2) {
    left: 73%;
    top: 60%;
  }
  :nth-of-type(3) {
    left: 62.5%;
    top: 66%;
  }
  :nth-of-type(4) {
    left: 62.5%;
    top: 53%;
  }
  :nth-of-type(5) {
    left: 49.5%;
    top: 54%;
  }
  :nth-of-type(6) {
    left: 49.5%;
    top: 43%;
  }
  :nth-of-type(7) {
    left: 44%;
    top: 49%;
  }
  :nth-of-type(8) {
    left: 44%;
    top: 39.5%;
  }
  :nth-of-type(9) {
    left: 35.5%;
    top: 42%;
  }
  :nth-of-type(10) {
    left: 35.5%;
    top: 32%;
  }
  :nth-of-type(11) {
    left: 31.5%;
    top: 38.5%;
  }
  :nth-of-type(12) {
    left: 31.5%;
    top: 29%;

    /* @media (max-width: 1699px) {
      width: 25px;
      height: 25px;
      font-size: 1.4rem;
    }

    @media (max-width: 1024px) {
      width: 8px;
      height: 8px;
      font-size: 0.4rem;
    }

    @media (min-width: 1025px) {
      left: 13.5%;
      top: 11%;
    }

    @media (min-width: 1700px) {
      left: 14.5%;
      top: 12%;
    } */
  }
  :nth-of-type(13) {
    left: 26%;
    top: 33%;

    /* @media (max-width: 1699px) {
      width: 25px;
      height: 25px;
      font-size: 1.4rem;
    }
    @media (max-width: 1024px) {
      width: 8px;
      height: 8px;
      font-size: 0.4rem;
    }

    @media (min-width: 1025px) {
      left: 10.5%;
      top: 9%;
    }

    @media (min-width: 1700px) {
      left: 12%;
      top: 10.5%;
    } */
  }
  :nth-of-type(14) {
    left: 26%;
    top: 26%;
  }
  :nth-of-type(15) {
    left: 23%;
    top: 30.5%;
  }
  :nth-of-type(16) {
    left: 23%;
    top: 24%;
  }
  :nth-of-type(17) {
    left: 19%;
    top: 27.5%;
  }
  :nth-of-type(18) {
    left: 19%;
    top: 21.5%;
  }
  :nth-of-type(19) {
    left: 16.5%;
    top: 25%;
  }
  :nth-of-type(20) {
    left: 16.5%;
    top: 19.5%;
  }
  :nth-of-type(21) {
    left: 14%;
    top: 23%;
  }
  :nth-of-type(22) {
    left: 14%;
    top: 18%;
  }
  :nth-of-type(23) {
    left: 12%;
    top: 21.5%;
  }
  :nth-of-type(24) {
    left: 12%;
    top: 16%;
  }
  :nth-of-type(25) {
    left: 9.5%;
    top: 19.5%;
  }
  :nth-of-type(26) {
    left: 9.5%;
    top: 14%;
  }
`;

const RendProInteractiveVis = () => {
  const { allDatoCmsHouse, file } = useStaticQuery(query);
  const { width } = useWindowSize();
  const primary = '#E7B52E';

  return (
    <StyledWrapper>
      <RendproInteractiveVisualization
        data={allDatoCmsHouse.nodes}
        subpagePrefix="/mieszkanie"
        navigate={navigate}
        vector={VECTOR}
        image={() => (
          <StyledImage
            image={getImage(file.childImageSharp.gatsbyImageData)}
            alt="Osiedle Zielone Wzgórza"
          />
        )}
        legend={Legend}
        box={Box}
        width={width}
        boxCoords={BOX_COORDS}
        checkbox={CheckboxNew}
        color={primary}
        icons={{
          move: moveIcon,
          zoomIn: zoomInIcon,
          zoomOut: zoomOutIcon,
        }}
        indication={StyledIndication as any}
        mobileNavItem={StyledMobileNavItem as any}
        // animation={{ mobileNavItemsInSameTime: true }}
      />
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "interactive_visualization" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
    allDatoCmsHouse(sort: { order: ASC, fields: [originalId] }) {
      nodes {
        originalId
        area
        availability
        deliveryDate
        garage
        number
        parcelArea
        price
        roomNumber
      }
    }
  }
`;

export default RendProInteractiveVis;
